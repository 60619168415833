<template>
  <div>
    <div class="pt-5 px-8">
      <PageHeader
        hide-menu
        page-title="Будущие партнеры"
        search-placeholder="Поиск по менеджеру"
        @search-input="searchInput = $event"
      />
      <VDivider class="my-4" />
      <div class="d-flex my-4">
        <Dropdown
          height="420"
          :items="citiesShort"
          no-header
          searchable
          :selected="selected.cities"
          single-select
          title="Все регионы"
          @selected="selected.cities = $event"
        />
        <Dropdown
          :items="states"
          no-header
          :selected="selected.states"
          single-select
          title="Все статусы"
          @selected="selected.states = $event"
        />
      </div>
    </div>
    <UniversalTable
      ref="table"
      :headers="incomePartnersHeaders()"
      :items="incomePartners"
      :page="currentPage"
      @assign="assignManager"
      @processed="markAsProcessed"
      @update-page="getIncomePartners"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  fetchIncomePartners,
  assignManagerToIncomePartner,
  markIncomePartnerAsProcessed,
} from '@/api/api';
import { incomePartnersHeaders } from '@/conditions/tablesHeaders';
import { itemsPerPage } from '@/helpers/table';
import PageHeader from '@/components/reusable/PageHeader.vue';
import UniversalTable from '@/components/reusable/UniversalTable.vue';
import Dropdown from '@/components/reusable/DropdownTable.vue';

export default {
  components: { Dropdown, UniversalTable, PageHeader },
  data() {
    return {
      incomePartners: [],
      incomePartnersHeaders,
      currentPage: 1,
      states: [
        { title: 'Новая заявка', value: 'new' },
        { title: 'В обработке', value: 'in_processing' },
        { title: 'Обработан', value: 'processed' },
      ],
      selected: {
        cities: [],
        states: [],
      },
      searchInput: '',
    };
  },
  mounted() {
    this.getCitiesShort().finally(() => {
      this.getIncomePartners();
    });
  },
  computed: {
    ...mapGetters(['citiesShort']),
  },
  methods: {
    ...mapActions(['getCitiesShort']),
    getIncomePartners(page = this.currentPage) {
      const params = {
        page,
        page_size: itemsPerPage,
        city_id: this.selected.cities[0]?.id,
        state: this.selected.states[0]?.value,
        manager: this.searchInput,
      };
      this.$refs.table.loading = true;
      fetchIncomePartners(params).then((response) => {
        this.incomePartners = response.result;
        // eslint-disable-next-line no-param-reassign,no-return-assign
        this.incomePartners.forEach((partner) => partner.cities = this.citiesShort
          .filter((city) => city.id === partner.cityId));
        this.currentPage = response.page;
        this.$refs.table.totalPage = response.total_pages;
        this.$refs.table.totalCount = response.total_count;
      }).finally(() => {
        this.$refs.table.loading = false;
      });
    },
    assignManager(incomePartner) {
      assignManagerToIncomePartner(incomePartner).then(() => {
        this.getIncomePartners();
      });
    },
    markAsProcessed(incomePartner) {
      markIncomePartnerAsProcessed(incomePartner).then(() => {
        this.getIncomePartners();
      });
    },
  },
  watch: {
    searchInput() {
      this.getIncomePartners();
    },
    selected: {
      deep: true,
      handler() {
        this.getIncomePartners();
      },
    },
  },
};
</script>
